import { Component } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../../utilities/messenger";
import Lang from "../../LanguageSelect/Lang";
import HexInput from "./HexInput";
import tokenHandler from "../../../utilities/tokenHandler";

class LoggerCommandModal extends Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.systemConfigShowLoggerCommandModal && this.props.systemConfigShowLoggerCommandModal) {
            this.fetchCommandHistory();
        }
        if(prevProps.commandHistory !== this.props.commandHistory){
            console.log("Command history updated:", this.props.commandHistory);
        }
    }

    closeModal = () => {
        const { setValues } = this.props;
        setValues({ systemConfigShowLoggerCommandModal: false, systemConfigCommandLoggerId: "", systemConfigCommandLoggerName: "", systemConfigLoggerCommand: "", commandHistory: []});
    };

    handleLoggerCommand = () => {
        const { systemConfigCommandLoggerId, customerId, systemsSelectedSystem, systemConfigCommandLoggerName, systemConfigLoggerCommand, setValues, restartPolling } = this.props;
        if (systemConfigLoggerCommand.trim() === "") {
            toast.warning(<Lang fi="Syötä komento" />);
            return;
        }
        if (systemConfigCommandLoggerId.length === 8) {
            const payload = {
                customerId,
                systemId: systemsSelectedSystem.id,
                loggerId: parseInt(systemConfigCommandLoggerId, 16),
                loggerName: systemConfigCommandLoggerName !== undefined && systemConfigCommandLoggerName !== "" ? systemConfigCommandLoggerName : `Node_${systemConfigCommandLoggerId}`,
                command: systemConfigLoggerCommand
            };
            const callback = (data) => {
                switch (data.state) {
                    case "ok": {
                        setValues({
                            systemConfigCommandLoggerId: "",
                            systemConfigCommandLoggerName: "",

                        });
                        restartPolling();
                        break;
                    }
                }
            };
            this.closeModal();
            toast.success(<Lang fi="Komento lähetetty" />)
            Messenger.sendNodeCommand({ payload, callback: callback.bind(this) });
        } else {
            toast.warning(<Lang fi="Komennon lähetys epäonnistui" />)
        }
    };

    fetchCommandHistory = () => {
        const { systemConfigCommandLoggerId, customerId, systemsSelectedSystem, setValues} = this.props;
        if (systemConfigCommandLoggerId.length === 8) {
            const payload= {
                customerId,
                systemId: systemsSelectedSystem.id,
                loggerId: parseInt(systemConfigCommandLoggerId, 16),
            };
            const callback = (data) => {
                if (data) {
                    setValues({ commandHistory: data.commandHistory });
                    console.log(data)
                } else {
                    console.error("Failed to fetch command history")
                }
            };
            Messenger.requestNodeCommandHistory({ payload, callback });
        }
    };

    renderCommandHistory() {
        const { commandHistory } = this.props;

        /*console.log(commandHistory)*/

        if (!Array.isArray(commandHistory) || commandHistory.length === 0) {
            return <p>No command history available.</p>;
        }

        return (
            <table className="command-history-table">
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>NID</th>
                        <th>Command</th>
                        <th>Response</th>
                    </tr>
                </thead>
                <tbody>
                    {commandHistory.map((item, index) => (
                        <tr key={index}>
                            <td>{new Date(item.timestamp * 1000).toLocaleString()}</td>
                            <td>{item.nid}</td>
                            <td>{item.command}</td>
                            <td>{item.response}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }


    render() {
        const { systemConfigShowLoggerCommandModal, systemConfigCommandLoggerId, setValues, systemConfigCommandLoggerName, systemConfigLoggerCommand } = this.props;
        
        return (
            <Modal
                key="commandloggermodal"
                className="command-logger-modal"
                show={systemConfigShowLoggerCommandModal}
                onHide={this.closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Lang fi={`Komento loggerille: ${systemConfigCommandLoggerName} (${systemConfigCommandLoggerId})`}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>
                        <Lang fi="Komento" />
                        <Form.Control 
                            className="logger-command-input"
                            type="text"
                            value={systemConfigLoggerCommand}
                            onChange={(e) => setValues({ systemConfigLoggerCommand: e.target.value })}
                        />
                    </Form.Label>
                    <h5>Historia</h5>
                    {this.renderCommandHistory()}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="success"
                        onClick={this.handleLoggerCommand}
                    >
                        <Lang fi="Lähetä" />
                    </Button>
                    <Button
                        variant="danger"
                        onClick={this.closeModal}
                    >
                        <Lang en="Cancel" fi="Peruuta" sv="Ångra" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { customerId, systemConfigShowLoggerCommandModal, systemConfigCommandLoggerId, systemConfigCommandLoggerName, systemsSelectedSystem, systemConfigLoggerCommand, commandHistory } = state;
    return { customerId, systemConfigShowLoggerCommandModal, systemConfigCommandLoggerId, systemConfigCommandLoggerName, systemsSelectedSystem, systemConfigLoggerCommand, commandHistory };
}

const mapDispatchToProps = (dispatch) => ({
    setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggerCommandModal);