import "../../../css/Spinner.css";
import "./LoggersList.css";

import { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import { IconContext } from "react-icons";
import { BsPlus, BsX } from "react-icons/bs";
import { connect } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import Messenger from "../../../utilities/messenger";
import Lang from "../../LanguageSelect/Lang";
import { fetchSystemConfig } from "../../Systems/SystemsFunctionSelect";
import Timeselector from "../SystemSettings/Timeselector/Timeselector";
import AddLoggerModal from "./AddLoggerModal";
import FilterCoefDropdown from "./FilterCoefDropdown";
import MetadataNumberField from "./MetadataNumberField";
import RemoveLoggerModal from "./RemoveLoggerModal";
import LoggerCommandModal from "./LoggerCommandModal";
import isAuthorized from "../../../utilities/authorization";

const rfdclone = rfdc();

const mobileStyle = " mobile-logger-wrapper";

const getType = (i, loggerId, sensor, sensorTypes) => sensorTypes[loggerId].split(",")[i] === undefined ? sensor.b[4] : sensorTypes[loggerId].split(",")[i];

const unitTranslation = (value) => value === "'C" ? "°C" : value;

const HEX_ZEROES = "00000000";

const getHex = (integer) => {
	const string = integer.toString(16);
	return HEX_ZEROES.slice(0, Math.max(0, (8 - string.length))) + string.toUpperCase();
};

class LoggersList extends Component {
	constructor() {
		super();

		this.pollTimeout = undefined;
	}

	componentDidMount() {
		const { setValues } = this.props;
		setValues({ systemsConfigLastLoggerStateStatus: undefined });
	}

	componentDidUpdate(prevProps) {
		const { systemsSelectedSystem, systemsSelectedFunction, setValues } = this.props;
		if (
			systemsSelectedSystem &&
			(this.pollTimeout === undefined || (prevProps.systemsSelectedSystem && systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id)) &&
			systemsSelectedSystem.id !== "all" && systemsSelectedFunction === "config"
		) {
			if (systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id) {
				// Clearing timeout when unselected
				clearTimeout(this.pollTimeout);
				this.pollTimeout = undefined;
				setValues({ systemsConfigRemovedLoggers: [] });
			}
			// Initiating the add logger que polling
			this.pollLoggerState();
		}
		if (this.pollTimeout !== undefined &&
			(!systemsSelectedSystem || systemsSelectedSystem.id === "all" || systemsSelectedFunction !== "config")
		) {
			// Clearing timeout when unselected
			clearTimeout(this.pollTimeout);
			this.pollTimeout = undefined;
			setValues({ systemsConfigRemovedLoggers: [] });
		}
	}

	componentWillUnmount() {
		// Clearing timeout when unselected
		if (this.pollTimeout !== undefined) {
			clearTimeout(this.pollTimeout);
			this.pollTimeout = undefined;
		}
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	pollLoggerState = () => {
		const { customerId, systemsSelectedSystem, systemsConfigLastLoggerStateStatus, systemsConfigRemovedLoggers, systemsConfigAddLoggers, setValues } = this.props;
		const payload = { customerId, systemId: systemsSelectedSystem.id };
		const callback = (data) => {
			if (data.state === "ok") {
				if (systemsConfigLastLoggerStateStatus === "pending") {
					if (Array.isArray(systemsConfigRemovedLoggers) && systemsConfigRemovedLoggers.length > 0) {
						setValues({ systemsConfigRemovedLoggers: [] });
					}
					if (Array.isArray(systemsConfigAddLoggers) && systemsConfigAddLoggers.length > 0) {
						setValues({ systemsConfigAddLoggers: [] });
					}
					fetchSystemConfig({ customerId: payload.customerId, systemId: payload.systemId });
					toast.success(<Lang en="System information updated [OK]" fi="Järjestelmän tiedot päivitetty [OK]" sv="System information updaterats [OK]" />, { autoClose: 20_000, });
				}
			} else if (data.state === "pending") {
				const update = {};
				if (Array.isArray(data.loggerIdsAdd)) { update.systemsConfigAddLoggers = data.loggerIdsAdd; }
				if (Array.isArray(data.loggerIdsRemove)) { update.systemsConfigRemovedLoggers = data.loggerIdsRemove; }
				setValues(update);
			} else {
				toast.error(<Lang en="An error occurred when fetching logger queue" fi="Virhe loggeri jonon haussa" sv="Fel vid sökning av logger kö" />);
			}
			setValues({ systemsConfigLastLoggerStateStatus: data.state });
		};
		Messenger.requestLoggerState({ payload, callback: callback.bind(this) });
		// Ensuring the timeout is empty
		if (this.pollTimeout !== undefined) {
			clearTimeout(this.pollTimeout);
			this.pollTimeout = undefined;
		}
		// Setting new timeout
		this.pollTimeout = setTimeout(this.pollLoggerState, 60_000);
	};

	handleLoggerNameChange = ({ value, nodeIndex }) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		systemsConfigNodeConfigCopy[nodeIndex].name = value.replace(/[+,:;|]/g, "");
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	handleNameChange = ({ value, nodeIndex, sensorIndex }) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].b[4] = value.replace(/[+,:;|]/g, "");
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	handleFilterChange = ({ values, metaData }) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const { nodeIndex, sensorIndex } = metaData;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		const ORIGINAL_F = systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].f === undefined ? {} : systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].f;
		systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].f = { ...ORIGINAL_F, ...values, type: "kalman" };
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	handleAlarmDelay = ({ value, sensorIndex, nodeIndex }) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].a[4] = value / 1000;
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	handleNumberChange = ({ value, metaData }) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const { nodeIndex, sensorIndex, propId, valueIndex } = metaData;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex][propId][valueIndex] = value;
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	handleSwitch = (value, sensorIndex, nodeIndex) => {
		const { systemsConfigNodeConfig, setValues } = this.props;
		const systemsConfigNodeConfigCopy = rfdclone(systemsConfigNodeConfig);
		systemsConfigNodeConfigCopy[nodeIndex].s[sensorIndex].a[5] = value;
		setValues({ systemsConfigNodeConfig: systemsConfigNodeConfigCopy });
	};

	loggerRemovalButtonClick = ({ loggerId, loggerName }) => {
		const { setValues } = this.props;
		setValues({ systemsConfigShowRemoveLoggerModal: true, systemsConfigRemoveLoggerId: loggerId, systemsConfigRemoveLoggerName: loggerName });
	};

	loggerCommandButtonClick = ({ loggerId, loggerName }) => {
		const { setValues } = this.props;
		setValues({systemConfigShowLoggerCommandModal: true, systemConfigCommandLoggerId: loggerId, systemConfigCommandLoggerName: loggerName });
	};

	restartPolling = () => {
		const { setValues } = this.props;
		// Clearing timeout when unselected
		clearTimeout(this.pollTimeout);
		this.pollTimeout = undefined;
		setValues({ systemsConfigRemovedLoggers: [] });
		// Initiating the add logger que polling
		this.pollLoggerState();
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	getSensors = (sensors, info, nodeIndex, sensorTypes, loggerId, editAuth, loading) => {
		if (!loading && Array.isArray(sensors) && info && nodeIndex !== undefined) {
			if (info.deviceType === "APw" || info.deviceType === "APw+") {
				return sensors.map((sensor, i) => (
					<tr key={`sensors_list_${nodeIndex}_${sensor.b[0]}`}>
						<td>
							<Form.Control
								disabled={!editAuth}
								className="name-input"
								type="text"
								value={sensor.b[4]}
								maxLength={15}
								onChange={(e) => this.handleNameChange({ value: e.target.value, nodeIndex, sensorIndex: i })}
							/>
						</td>
						<td>{getType(i, loggerId, sensor, sensorTypes)}</td>
						<td>{unitTranslation(sensor.b[3])}</td>
						<td>{`${sensor.b[1] / 1000}s`}</td>
						<td className="number-input">
							<MetadataNumberField
								disabled={!editAuth}
								className="limit-input"
								value={sensor.a[2]}
								decimals={1}
								updateAncestor={this.handleNumberChange}
								metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 2 }}
							/>
						</td>
						<td className="number-input">
							<MetadataNumberField
								disabled={!editAuth}
								className="limit-input"
								value={sensor.a[3]}
								decimals={1}
								updateAncestor={this.handleNumberChange}
								metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 3 }}
							/>
						</td>
						<td className="number-input">
							<MetadataNumberField
								disabled={!editAuth}
								className="limit-input"
								value={sensor.a[0]}
								decimals={1}
								updateAncestor={this.handleNumberChange}
								metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 0 }}
							/>
						</td>
						<td className="number-input">
							<MetadataNumberField
								disabled={!editAuth}
								className="limit-input"
								value={sensor.a[1]}
								decimals={1}
								updateAncestor={this.handleNumberChange}
								metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 1 }}
							/>
						</td>
						<td className="alarm-delay-selector">
							<Timeselector
								disabled={!editAuth}
								onChange={(value) => this.handleAlarmDelay({ value, nodeIndex, sensorIndex: i })}
								hours minutes seconds
								value={sensor.a[4] * 1000}
							/>
						</td>
					</tr>
				));
			}
			if (info.deviceType === "DL-P1") {
				return sensors.map((sensor, i) => (
					<tr key={`sensors_list_${nodeIndex}_${sensor.b[0]}`}>
						<td>
							<Form.Control
								disabled={!editAuth}
								className="name-input"
								type="text"
								value={sensor.b[4]}
								maxLength={15}
								onChange={(e) => this.handleNameChange({ value: e.target.value, nodeIndex, sensorIndex: i })}
							/>
						</td>
						<td>Pres</td>
						<td>{unitTranslation(sensor.b[3])}</td>
						<td>{`${sensor.b[1] / 1000}s`}</td>
						<td>
							{
								sensor.f === undefined ?
									"-" : <FilterCoefDropdown
										disabled={!editAuth}
										onChange={this.handleFilterChange}
										values={sensor.f === undefined ? { q: 2, r: 0.01 } : sensor.f}
										metaData={{ nodeIndex, sensorIndex: i }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-" : <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[2]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 2 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-" : <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[3]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 3 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-" : <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[0]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 0 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-" : <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[1]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 1 }}
									/>
							}
						</td>
						<td className="alarm-delay-selector">
							{
								sensor.a === undefined ?
									"-" : <Timeselector
										disabled={!editAuth}
										onChange={(value) => this.handleAlarmDelay({ value, nodeIndex, sensorIndex: i })}
										hours minutes seconds
										value={sensor.a[4] * 1000}
									/>
							}
						</td>
						<td>
							{
								sensor.a === undefined ?
									"-" : <Switch
										disabled={!editAuth}
										onChange={(value) => this.handleSwitch(value, i, nodeIndex)}
										checked={sensor.a[5] === false || sensor.a[5] === true ? sensor.a[5] : false}
										onHandleColor="#5cb75c"
										onColor="#b1dbaf"
										height={13}
										width={33}
										handleDiameter={19}
										boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
										uncheckedIcon={false}
										checkedIcon={false}
									/>
							}
						</td>
					</tr>
				));
			}
			if (info.deviceType === "DL-P2") {
				return sensors.map((sensor, i) => (
					<tr key={`sensors_list_${nodeIndex}_${sensor.b[0]}`}>
						<td>
							<Form.Control
								disabled={!editAuth}
								className="name-input"
								type="text"
								value={sensor.b[4]}
								maxLength={15}
								onChange={(e) => this.handleNameChange({ value: e.target.value, nodeIndex, sensorIndex: i })}
							/>
						</td>
						<td>{getType(i, loggerId, sensor, sensorTypes)}</td>
						<td>{unitTranslation(sensor.b[3])}</td>
						<td>{`${sensor.b[1] / 1000}s`}</td>
						<td>
							{
								sensor.f === undefined ?
									"-"
									: <FilterCoefDropdown
										disabled={!editAuth}
										onChange={this.handleFilterChange}
										values={sensor.f === undefined ? { q: 2, r: 0.01 } : sensor.f}
										metaData={{ nodeIndex, sensorIndex: i }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-"
									: <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[2]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 2 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-"
									: <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[3]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 3 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-"
									: <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[0]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 0 }}
									/>
							}
						</td>
						<td className="number-input">
							{
								sensor.a === undefined ?
									"-"
									: <MetadataNumberField
										disabled={!editAuth}
										className="limit-input"
										value={sensor.a[1]}
										decimals={1}
										updateAncestor={this.handleNumberChange}
										metaData={{ nodeIndex, sensorIndex: i, propId: "a", valueIndex: 1 }}
									/>
							}
						</td>
						<td className="alarm-delay-selector">
							{
								sensor.a === undefined ?
									"-"
									: <Timeselector
										disabled={!editAuth}
										onChange={(value) => this.handleAlarmDelay({ value, nodeIndex, sensorIndex: i })}
										hours minutes seconds
										value={sensor.a[4] * 1000}
									/>
							}
						</td>
						<td>
							{
								sensor.a === undefined ?
									"-"
									: <Switch
										disabled={!editAuth}
										onChange={(value) => this.handleSwitch(value, i, nodeIndex)}
										checked={sensor.a[5] === false || sensor.a[5] === true ? sensor.a[5] : false}
										onHandleColor="#5cb75c"
										onColor="#b1dbaf"
										height={13}
										width={33}
										handleDiameter={19}
										boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
										uncheckedIcon={false}
										checkedIcon={false}
									/>
							}
						</td>
					</tr>
				));
			}
		}
		return null;
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	getLoggers = (nodes, info, sensorTypes, editAuth, loading) => {
		const { systemsConfigDeviceData, systemsConfigRemovedLoggers } = this.props;
		if (Array.isArray(nodes) && info) {
			if (info.deviceType === "APw" || info.deviceType === "APw+") {
				try {
					if (systemsConfigDeviceData !== undefined && Object.keys(systemsConfigDeviceData).length > 0)
						nodes.sort((a, b) => systemsConfigDeviceData[getHex(a.nid)].i - systemsConfigDeviceData[getHex(b.nid)].i);
				} finally {
					// pass
				}
				const res = []
					// eslint-disable-next-line no-restricted-syntax
				for (const [i, node] of nodes.entries()) {
					const loggerId = getHex(node.nid);
					const removed = Array.isArray(systemsConfigRemovedLoggers) && systemsConfigRemovedLoggers.includes(node.nid);
					res.push(
						<div key={`loggerblockwrapper_${node.nid}`} className={`logger-wrapper${isDesktop ? "" : mobileStyle}${removed ? " removed-logger" : ""}`}>
							<div className="logger-block">
								<div key={`logger_title_${node.nid}`} className="logger-title">
									<span className="title-main">
										<span className="ADDR">HWID: {loggerId}</span>
										<span className="name">
											<Lang en="Name: " fi="Nimi: " sv="Namn: " />
											<Form.Control
												disabled={!editAuth}
												className="logger-name-input"
												type="text"
												maxLength={15}
												value={node.name}
												onChange={(e) => this.handleLoggerNameChange({ value: e.target.value, nodeIndex: i })}
											/>
										</span>
										<span className="version">{node.ver}</span>
									</span>
									{isAuthorized({ one: ["super"] }) ? 
										<Button className="command-longger-btn" size="sm" variant="success" onClick={() => this.loggerCommandButtonClick({ loggerId, loggerName: node.name})}>Komento
										</Button> : null
									}
									{removed ? null : <span className="remove-logger-btn-wrapper"><Button className="remove-logger-btn" size="sm" variant="danger" onClick={() => this.loggerRemovalButtonClick({ loggerId, loggerName: node.name })}><IconContext.Provider value={{ className: "reactIcon" }}><BsX /></IconContext.Provider></Button></span>}
								</div>
								<Table className="sensors-list-table" key={`sensors-list_${node.nid}`} size="sm">
									<thead>
										<tr>
											<th aria-label={<Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" />}><Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" /></th>
											<th aria-label={<Lang en="Type" fi="Tyyppi" sv="Typ" />}><Lang en="Type" fi="Tyyppi" sv="Typ" /></th>
											<th aria-label={<Lang en="Unit" fi="Yksikkö" sv="Enhet" />}><Lang en="Unit" fi="Yksikkö" sv="Enhet" /></th>
											<th aria-label={<Lang en="Interval" fi="Mittausjakso" sv="Intervall" />}><Lang en="Interval" fi="Mittausjakso" sv="Intervall" /></th>
											<th aria-label={<Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" />} className="number-input-header"><Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" /></th>
											<th aria-label={<Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." />} className="number-input-header"><Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." /></th>
											<th aria-label={<Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" />} className="number-input-header"><Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" /></th>
											<th aria-label={<Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." />} className="number-input-header"><Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." /></th>
											<th aria-label={<Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" />}><Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" /></th>
										</tr>
									</thead>
									<tbody>
										{this.getSensors(node.s, info, i, sensorTypes, loggerId, editAuth, loading)}
									</tbody>
								</Table>
							</div>
						</div>
					);
					
				}
				return res;
			}
			if (info.deviceType === "DL-P1") {
				const res = [];
				
				// eslint-disable-next-line no-restricted-syntax
				for (const [i, node] of nodes.entries()) {
					const loggerId = getHex(node.nid);
					res.push(
						<div key={`loggerblockwrapper_${node.nid}`} className={`logger-wrapper${isDesktop ? "" : mobileStyle}`}>
							<div className="logger-block">
								<div key={`logger_title_${node.nid}`} className="logger-title">
									<span className="title-main">
										<span className="ADDR">HWID: {loggerId}</span>
										<span className="name">
											<Lang en="Name: " fi="Nimi: " sv="Namn: " />
											<Form.Control
												disabled={!editAuth}
												className="logger-name-input"
												type="text"
												value={node.name}
												onChange={(e) => this.handleLoggerNameChange({ value: e.target.value, nodeIndex: i })}
											/>
										</span>
									</span>
								</div>
								<Table className="sensors-list-table" key={`sensors-list_${node.nid}`} size="sm">
									<thead>
										<tr>
											<th aria-label={<Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" />}><Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" /></th>
											<th aria-label={<Lang en="Type" fi="Tyyppi" sv="Typ" />}><Lang en="Type" fi="Tyyppi" sv="Typ" /></th>
											<th aria-label={<Lang en="Unit" fi="Yksikkö" sv="Enhet" />}><Lang en="Unit" fi="Yksikkö" sv="Enhet" /></th>
											<th aria-label={<Lang en="Interval" fi="Mittausjakso" sv="Intervall" />}><Lang en="Interval" fi="Mittausjakso" sv="Intervall" /></th>
											<th aria-label={<Lang en="Average" fi="Keskiarvoistus" sv="Medeltal" />}><Lang en="Average" fi="Keskiarvoistus" sv="Medeltal" /></th>
											<th aria-label={<Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" />} className="number-input-header"><Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" /></th>
											<th aria-label={<Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." />} className="number-input-header"><Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." /></th>
											<th aria-label={<Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" />} className="number-input-header"><Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" /></th>
											<th aria-label={<Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." />} className="number-input-header"><Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." /></th>
											<th aria-label={<Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" />}><Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" /></th>
											<th aria-label={<Lang en="Automatic alarm ack." fi="Häl. autom. kuittaus" sv="Automatisk larm kvit." />}><Lang en="Automatic alarm ack." fi="Häl. autom. kuittaus" sv="Automatisk larm kvit." /></th>
										</tr>
									</thead>
									<tbody>
										{this.getSensors(node.s, info, i, sensorTypes, loggerId, editAuth, loading)}
									</tbody>
								</Table>
							</div>
						</div>
					)
				};
				return res;
			}
			if (info.deviceType === "DL-P2") {
				const res = [];
				
				// eslint-disable-next-line no-restricted-syntax
				for (const [i, node] of nodes.entries()) {
					const loggerId = getHex(node.nid);
					res.push(
						<div key={`loggerblockwrapper_${node.nid}`} className={`logger-wrapper${isDesktop ? "" : mobileStyle}`}>
							<div className="logger-block">
								<div key={`logger_title_${node.nid}`} className="logger-title">
									<span className="title-main">
										<span className="ADDR">HWID: {loggerId}</span>
										<span className="name">
											<Lang en="Name: " fi="Nimi: " sv="Namn: " />
											<Form.Control
												disabled={!editAuth}
												className="logger-name-input"
												type="text"
												value={node.name}
												onChange={(e) => this.handleLoggerNameChange({ value: e.target.value, nodeIndex: i })}
											/>
										</span>
									</span>
								</div>
								<Table className="sensors-list-table" key={`sensors-list_${node.nid}`} size="sm">
									<thead>
										<tr>
											<th aria-label={<Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" />}><Lang en="Sensor name" fi="Anturin nimi" sv="Sensor namn" /></th>
											<th aria-label={<Lang en="Type" fi="Tyyppi" sv="Typ" />}><Lang en="Type" fi="Tyyppi" sv="Typ" /></th>
											<th aria-label={<Lang en="Unit" fi="Yksikkö" sv="Enhet" />}><Lang en="Unit" fi="Yksikkö" sv="Enhet" /></th>
											<th aria-label={<Lang en="Interval" fi="Mittausjakso" sv="Intervall" />}><Lang en="Interval" fi="Mittausjakso" sv="Intervall" /></th>
											<th aria-label={<Lang en="Average" fi="Keskiarvoistus" sv="Medeltal" />}><Lang en="Average" fi="Keskiarvoistus" sv="Medeltal" /></th>
											<th aria-label={<Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" />} className="number-input-header"><Lang en="Low alert" fi="Hälytys alaraja" sv="Låg larm" /></th>
											<th aria-label={<Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." />} className="number-input-header"><Lang en="Low hyst." fi="Alaraja hyst." sv="Låg hyst." /></th>
											<th aria-label={<Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" />} className="number-input-header"><Lang en="High alert" fi="Hälytys yläraja" sv="Hög larm" /></th>
											<th aria-label={<Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." />} className="number-input-header"><Lang en="High hyst." fi="Yläraja hyst." sv="Hög hyst." /></th>
											<th aria-label={<Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" />}><Lang en="Alarm delay" fi="Hälytysviive" sv="Larm dröjsmål" /></th>
											<th aria-label={<Lang en="Automatic alarm ack." fi="Häl. autom. kuittaus" sv="Automatisk larm kvit." />}><Lang en="Automatic alarm ack." fi="Häl. autom. kuittaus" sv="Automatisk larm kvit." /></th>
										</tr>
									</thead>
									<tbody>
										{this.getSensors(node.s, info, i, sensorTypes, loggerId, editAuth, loading)}
									</tbody>
								</Table>
							</div>
						</div>
					);
				};
				return res;
			}
		}
		return null;
	};

	render() {
		const { systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigSensorTypes, editAuth, systemsLoading, setValues } = this.props;
		if (systemsConfigNodeConfig !== null && systemsConfigSystemInfo !== undefined) {
			return systemsConfigSystemInfo.deviceType === "APw" || systemsConfigSystemInfo.deviceType === "APw+" ? (
				<div className="loggers-list">
					{this.getLoggers(systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigSensorTypes, editAuth, systemsLoading)}
					<Button className="add-logger-btn" size="sm" variant="success" onClick={() => setValues({ systemsConfigShowAddLoggerModal: true })}><IconContext.Provider value={{ className: "reactIcon" }}><BsPlus /></IconContext.Provider></Button>
					<AddLoggerModal restartPolling={this.restartPolling} />
					<RemoveLoggerModal restartPolling={this.restartPolling} />
					<LoggerCommandModal restartPolling={this.restartPolling} />
				</div>
			) : (
				<div className="loggers-list">
					{this.getLoggers(systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigSensorTypes, editAuth, systemsLoading)}
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { customerId, systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigDeviceData, systemsSelectedSystem, systemsSelectedFunction, systemsConfigRemovedLoggers, systemsConfigAddLoggers, systemsConfigLastLoggerStateStatus, systemsLoading, systemsConfigSensorTypes } = state;
	return { customerId, systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigDeviceData, systemsSelectedSystem, systemsSelectedFunction, systemsConfigRemovedLoggers, systemsConfigAddLoggers, systemsConfigLastLoggerStateStatus, systemsLoading, systemsConfigSensorTypes };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggersList);
